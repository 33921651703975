<template>
  <Divider align="center" type="solid" class="mt-2"></Divider>
  <div class="field">
    <div class="flex justify-content-center">
      <div class="flex-grow-1 text-left" v-if="exchange">
        <label class="mr-2 text-reader fg-black bg-lightAmber" @click="exportJsonFile" v-tooltip="`alle Werte in eine Datei herunterladen`"><i class="fi fi-rr-upload" /> Export</label>
        <label class="text-reader fg-black bg-lightTeal" v-tooltip="`Datei mit gespeicherten Werten hochladen`"><i class="fi fi-rr-download" /> Import<input type="file" @change="uploadJsonFile" /></label>
      </div>
      <div class="flex-grow-1 text-right">
        <Button icon="pi pi-check" class="mr-2 bg-lightGreen" label="Speichern" @click="this.$emit('diagSave')" :disabled="saveCheck" />
        <Button icon="pi pi-times" class="bg-lightRed" label="Abbrechen" @click="this.$emit('diagAbort')" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { uploadJsonFile, exportJson } from '@/helpers';

export default defineComponent({
  name: "widgetDialogFooter",
  setup() {},
  emits: ['diagSave', 'diagAbort', 'import'],
  components: {
  },
  props: {
    exchange: {
      type: Boolean,
      required: false
    },
    format: {
      type: String,
      required: false
    },
    widgetName: {
      type: String,
      required: true
    },
    department: {
      type: String,
      required: true
    },
    saveCheck: {
      type: Boolean,
      required: false
    },
    exportObject: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      currentObject: null,
    }
  },
  watch: {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    uploadJsonFile(ev) {
      this.currentObject = this.exportObject;
      uploadJsonFile(ev, this.$root, this.widgetName, this.currentObject);
      if (this.currentObject !== null) this.$emit('import', this.currentObject);
    },
    exportJsonFile() {
      exportJson(this.exportObject, this.widgetName, this.department);
    },
  },
});
</script>

<style lang="scss" scoped>
</style>