<template>
  <div v-if="this.alarmQueue > 0" class="hidden md:inline-flex social-icon" v-badge="this.alarmQueue" @click="toogleAlarmList">
      <i :class="`${icon1} fs-xxlarge icon-md`"></i>
  </div>
  <div v-else class="hidden md:inline-flex social-icon">
      <i :class="`${icon1} fs-xxlarge icon-md`"></i>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "moduleIconEnat",
  setup() {},
  components: {
  },
  emits: ['toogleAlarmOverlay'],
  props: {
    icon: {
      type: String,
      required: true
    },
    fgColor: {
      type: String,
      required: false
    },
    bgColor: {
      type: null,
      required: false
    },
    badge: {
      type: null,
      required: false
    },
    alarmStatus: {
      type: Number,
      required: false
    },
    alarmQueue: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {

  },
  computed: {
    icon1: function () {
      if (this.alarmStatus === 0) {
          return this.icon
      } else {
          return `${this.icon} bg-red pulse-loop cursor-pointer`;
      }
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `fg-${this.fgColor}`;
      }
      return '';
    },
    badge1: function () {
      if (this.badge) {
        return this.badge.toString();
      }
      return null;
    },
  },
  mounted() {
  },
  methods: {
    toogleAlarmList(event) {
      this.$emit('toogleAlarmOverlay', event);
    },
  },
});
</script>

<style lang="scss" scoped>
.pulse-loop {
  animation: pulse 1s;
  animation-iteration-count: infinite;
}
@keyframes pulse {
  0% {
      opacity: 0.8;
      text-shadow: black 2px 0 10px;
      transform: scale(0.9);
  }

  30% {
      transform: scale(1.1);
      text-shadow: gray 1px 0 10px;
  }

  100% {
      transform: scale(1);
      opacity: 1;
  }
}
</style>